
import { defineComponent } from 'vue'
// import moment from 'moment'
import { ClassroomModel } from '@/models'
import { useToast } from 'vue-toastification'

export default defineComponent({
  name: 'Search',
  setup() {
    const toast = useToast()
    return {
      toast,
    }
  },
  data() {
    return {
      query: this.$route.query.q as string,
      queryDecode: '' as string,
      listClassroomData: [] as any,
      loadedClassroomData: 10,
      totalClassroomData: 0,
      loadingClassroomData: false,

      listVideoData: [] as any,
      loadedVideoData: 10,
      totalVideoData: 0,
      loadingVideoData: false,

      listModuleData: [] as any,
      loadedModuleData: 10,
      totalModuleData: 0,
      loadingModuleData: false,

      loading: false,
    }
  },
  mounted() {
    // console.log(this.queryDecode)
    this.queryDecode = decodeURI(this.query)
    this.seaching()
  },
  watch: {
    $route() {
      this.query = this.$route.query.q as string
      this.queryDecode = decodeURI(this.query)
      this.seaching()
    },
  },
  methods: {
    async seaching() {
      this.loading = true
      await this.init()
      this.loading = false
    },
    init() {
      return new Promise<void>(() => {
        this.getListClassroom()
        this.getListVideo()
        this.getListModule()
      })
    },
    async getListClassroom() {
      this.loadingClassroomData = true
      await ClassroomModel.searching(
        this.query,
        1,
        this.loadedClassroomData,
        'classroom'
      )
        .then(res => {
          this.loadingClassroomData = false
          if (res?.data?.classroom?.data?.length > 0) {
            this.listClassroomData = res.data.classroom.data
            this.totalClassroomData = res.data.classroom.pages.total
          }
        })
        .catch(err => {
          this.loadingClassroomData = false
          console.log(err)
        })
    },
    loadMoreListClassroom() {
      this.loadedClassroomData += 5
      if (this.loadedClassroomData > this.totalClassroomData) {
        this.loadedClassroomData = this.totalClassroomData
      }
      this.getListClassroom()
    },
    async getListVideo() {
      this.loadingVideoData = true
      await ClassroomModel.searching(
        this.query,
        1,
        this.loadedVideoData,
        'subject'
      )
        .then(res => {
          this.loadingVideoData = false
          if (res?.data?.subject?.data?.length > 0) {
            this.listVideoData = res.data.subject.data
            this.totalVideoData = res.data.subject.pages.total
          }
          // console.log('video',this.listVideoData)
        })
        .catch(err => {
          this.loadingVideoData = false
          console.log(err)
        })
    },
    loadMoreListVideo() {
      this.loadedVideoData += 5
      if (this.loadedVideoData > this.totalVideoData) {
        this.loadedVideoData = this.totalVideoData
      }
      this.getListVideo()
    },
    async getListModule() {
      this.loadingModuleData = true
      await ClassroomModel.searching(
        this.query,
        1,
        this.loadedModuleData,
        'module'
      )
        .then(res => {
          this.loadingModuleData = false
          if (res?.data?.module?.data?.length > 0) {
            this.listModuleData = res.data.module.data
            this.totalModuleData = res.data.module.pages.total
          }
        })
        .catch(err => {
          this.loadingModuleData = false
          console.log(err)
        })
    },
    loadMoreListModule() {
      this.loadedModuleData += 5
      if (this.loadedModuleData > this.totalModuleData) {
        this.loadedModuleData = this.totalModuleData
      }
      this.getListModule()
    },
  },
})
