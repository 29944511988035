<template>
  <div class="h-auto min-h-[90vh]">
    <div
      class="mt-6 md:mt-10 mb-5 max-w-6xl px-3 md:px-6 mx-auto flex items-center"
    >
      <p class="font-desain text-2xl font-semibold text-[#212121]">
        Hasil pencarian dari kata kunci “{{ queryDecode }}”
      </p>
    </div>
    <div
      v-if="
        listClassroomData?.length == 0 &&
          listVideoData?.length == 0 &&
          listModuleData?.length == 0
      "
      class="max-w-6xl mx-auto md:px-6 w-full flex flex-row items-center"
    >
      <div
        class="bg-[#FFFFFF] rounded-md flex flex-col justify-center items-center min-h-[calc(100vh-12rem)] w-full"
      >
        <img
          src="/assets/images/mentor/empty-state.svg"
          class="w-3/4 md:w-1/3"
          alt=""
        />
        <p class="font-solusi text-[#383838] font-semibold mt-3 text-center">
          Hasil pencarian tidak ditemukan
        </p>
      </div>
    </div>
    <div
      v-if="listClassroomData?.length > 0"
      class="max-w-6xl mx-auto md:px-6 w-full flex flex-row items-center"
    >
      <div class="flex flex-col w-full">
        <div
          class="p-3 bg-[#F5F7FD] font-desain text-[#333333] font-semibold text-lg md:rounded-t-md"
        >
          Kelas
        </div>
        <div
          class="px-3 pt-3 pb-6 bg-[#FFFFFF] grid grid-cols-12 gap-3 md:rounded-b-md"
        >
          <div
            v-for="(classroom, i) in listClassroomData"
            :key="i"
            class="col-span-12 md:col-span-3"
          >
            <div
              class="p-2 rounded-md bg-[#FFFFFF] hover:bg-[#EEF2FF] duration-300 w-full shadow hover:shadow-md"
            >
              <div
                class="flex flex-row md:flex-col justify-between md:min-h-[18rem] space-x-3 md:space-x-0"
              >
                <img
                  @click.prevent="$router.push('/classroom/' + classroom.code)"
                  :src="classroom.thumbnail"
                  alt=""
                  class="rounded-md md:rounded-t-md object-cover object-center h-24 md:h-36 w-24 md:w-full cursor-pointer"
                />
                <div
                  class="flex flex-col flex-grow justify-between md:my-2 relative"
                >
                  <router-link
                    :to="'/classroom/' + classroom.code + '/activity'"
                    v-tooltip="{
                      content: classroom.name,
                    }"
                    class="text-sm font-solusi font-semibold text-[#383838] text-left line-clamp-2 overflow-hidden mb-2 cursor-pointer"
                  >
                    {{ classroom.name }}
                  </router-link>
                  <div
                    v-if="classroom?.mentor_data"
                    class="flex items-center w-full"
                  >
                    <img
                      :src="classroom?.mentor_data[0]?.photo"
                      alt=""
                      class="rounded-full h-10 w-10"
                    />
                    <div class="flex flex-col w-full ml-2 truncate">
                      <p
                        class="font-solusi text-xs text-[#212121] font-semibold text-left mb-1"
                      >
                        {{ classroom?.mentor_data[0]?.name }}
                      </p>
                      <p class="font-solusi text-2xs text-[#383838] text-left">
                        {{ classroom?.mentor_data[0]?.attribution }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="hidden md:flex flex-col">
                  <router-link
                    :to="'/classroom/' + classroom?.code + '/activity'"
                    class="w-full rounded-full text-white font-solusi text-xs text-center py-2 font-semibold mt-2 bg-[#00446F]"
                  >
                    Lihat
                  </router-link>
                </div>
              </div>
              <div class="flex md:hidden justify-center items-center">
                <router-link
                  :to="'/classroom/' + classroom?.code + '/activity'"
                  class="w-full rounded-full text-white font-solusi text-xs text-center py-2 font-semibold mt-2 bg-[#00446F]"
                >
                  Lihat
                </router-link>
              </div>
            </div>
          </div>
          <div
            v-if="loadedClassroomData < totalClassroomData"
            class="col-span-12 flex justify-center items-center mt-2"
          >
            <svg
              v-if="loadingClassroomData && totalClassroomData > 0"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="spinner"
              class="w-7 h-7 animate-spin text-[#00446F]"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
              ></path>
            </svg>
            <button
              v-else
              class="text-[#0A72ED] hover:text-[#00446F] font-solusi font-semibold text-sm duration-300"
              @click="loadMoreListClassroom"
            >
              Lebih Banyak
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="listVideoData?.length > 0"
      class="max-w-6xl mx-auto md:px-6 w-full flex flex-row items-center"
    >
      <div class="flex flex-col w-full">
        <div
          class="p-3 bg-[#F5F7FD] font-desain text-[#333333] font-semibold text-lg md:rounded-t-md"
        >
          Video
        </div>
        <div
          class="px-3 pt-3 pb-6 bg-[#FFFFFF] grid grid-cols-12 gap-3 md:rounded-b-md"
        >
          <div
            v-for="(subject, i) in listVideoData"
            :key="i"
            class="col-span-12 md:col-span-3"
          >
            <div
              class="p-2 rounded-md bg-[#FFFFFF] hover:bg-[#EEF2FF] duration-300 w-full shadow hover:shadow-md"
            >
              <div
                class="flex flex-row md:flex-col justify-between md:min-h-[14.5rem] space-x-3 md:space-x-0"
              >
                <div class="relative">
                  <img
                    @click.prevent="
                      $router.push(
                        '/classroom/' +
                          subject?.classroom?.code.substring(0, 4) +
                          '/activity?video=' +
                          subject?.id
                      )
                    "
                    :src="subject.thumbnail"
                    alt=""
                    class="rounded-md md:rounded-t-md object-cover object-center h-24 md:h-36 w-24 md:w-full cursor-pointer bg-gray-300"
                  />
                  <div
                    class="absolute top-0 left-0 flex justify-center items-center w-full h-full bg-white bg-opacity-20 cursor-pointer"
                  >
                    <svg
                      class="w-12 h-12 md:w-16 md:h-16"
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="40" cy="40" r="40" fill="#F5F6F8" />
                      <path
                        d="M29.8672 52.615C29.8672 54.7384 31.377 55.6079 33.223 54.5486L55.1493 41.9326C56.9954 40.8709 56.9954 39.1319 55.1493 38.0703L33.223 25.4531C31.3769 24.3914 29.8672 25.2597 29.8672 27.3866V52.615Z"
                        fill="#212121"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  class="flex flex-col flex-grow justify-between mt-2 relative"
                >
                  <router-link
                    :to="
                      '/classroom/' +
                        subject?.classroom?.code.substring(0, 4) +
                        '/activity?video=' +
                        subject?.id
                    "
                    v-tooltip="{
                      content: subject.title,
                    }"
                    class="text-sm font-solusi font-semibold text-[#383838] text-left line-clamp-2 overflow-hidden cursor-pointer"
                  >
                    {{ subject.title }}
                  </router-link>
                  <div class="flex flex-col items-end">
                    <router-link
                      :to="
                        '/classroom/' +
                          subject?.classroom?.code.substring(0, 4) +
                          '/activity?video=' +
                          subject?.id
                      "
                      class="w-fit-content md:w-full rounded-full text-white font-solusi text-xs text-center px-10 py-2 font-semibold mt-2 bg-[#00446F]"
                    >
                      Lihat
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="loadedVideoData < totalVideoData"
            class="col-span-12 flex justify-center items-center mt-2"
          >
            <svg
              v-if="loadingVideoData && totalVideoData > 0"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="spinner"
              class="w-7 h-7 animate-spin text-[#00446F]"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
              ></path>
            </svg>
            <button
              v-else
              class="text-[#0A72ED] hover:text-[#00446F] font-solusi font-semibold text-sm duration-300"
              @click="loadMoreListVideo"
            >
              Lihat Semua
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="listModuleData?.length > 0"
      class="max-w-6xl mx-auto md:px-6 w-full flex flex-row items-center"
    >
      <div class="flex flex-col w-full">
        <div
          class="p-3 bg-[#F5F7FD] font-desain text-[#333333] font-semibold text-lg md:rounded-t-md"
        >
          Modul
        </div>
        <div
          class="px-3 pt-3 pb-6 bg-[#FFFFFF] grid grid-cols-12 gap-3 md:rounded-b-md"
        >
          <div
            v-for="(mod, i) in listModuleData"
            :key="i"
            class="col-span-12 md:col-span-3"
          >
            <div
              class="p-2 rounded-md bg-[#FFFFFF] hover:bg-[#EEF2FF] duration-300 w-full shadow hover:shadow-md"
            >
              <div
                class="flex flex-row md:flex-col justify-between md:min-h-[14.5rem] space-x-3 md:space-x-0"
              >
                <img
                  @click.prevent="
                    $router.push(
                      '/classroom/' +
                        mod?.classroom?.code +
                        '/activity?module=' +
                        mod?.id
                    )
                  "
                  :src="mod.thumbnail ? mod.thumbnail : mod.classroom.thumbnail"
                  alt=""
                  class="rounded-md md:rounded-t-md object-cover object-center h-24 md:h-36 w-24 md:w-full cursor-pointer"
                />
                <div
                  class="flex flex-col flex-grow justify-between mt-2 relative"
                >
                  <router-link
                    :to="
                      '/classroom/' +
                        mod?.classroom?.code +
                        '/activity?module=' +
                        mod?.id
                    "
                    v-tooltip="{
                      content: mod.title,
                    }"
                    class="text-sm font-solusi font-semibold text-[#383838] text-left line-clamp-2 overflow-hidden cursor-pointer"
                  >
                    {{ mod.title }}
                  </router-link>
                  <div class="flex flex-col items-end">
                    <router-link
                      :to="
                        '/classroom/' +
                          mod?.classroom?.code +
                          '/activity?module=' +
                          mod?.id
                      "
                      class="w-fit-content md:w-full rounded-full text-white font-solusi text-xs text-center px-10 py-2 font-semibold mt-2 bg-[#00446F]"
                    >
                      Lihat
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="loadedModuleData < totalModuleData"
            class="col-span-12 flex justify-center items-center mt-2"
          >
            <svg
              v-if="loadingModuleData && totalModuleData > 0"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="spinner"
              class="w-7 h-7 animate-spin text-[#00446F]"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
              ></path>
            </svg>
            <button
              v-else
              class="text-[#0A72ED] hover:text-[#00446F] font-solusi font-semibold text-sm duration-300"
              @click="loadMoreListModule"
            >
              Lihat Semua
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
// import moment from 'moment'
import { ClassroomModel } from '@/models'
import { useToast } from 'vue-toastification'

export default defineComponent({
  name: 'Search',
  setup() {
    const toast = useToast()
    return {
      toast,
    }
  },
  data() {
    return {
      query: this.$route.query.q as string,
      queryDecode: '' as string,
      listClassroomData: [] as any,
      loadedClassroomData: 10,
      totalClassroomData: 0,
      loadingClassroomData: false,

      listVideoData: [] as any,
      loadedVideoData: 10,
      totalVideoData: 0,
      loadingVideoData: false,

      listModuleData: [] as any,
      loadedModuleData: 10,
      totalModuleData: 0,
      loadingModuleData: false,

      loading: false,
    }
  },
  mounted() {
    // console.log(this.queryDecode)
    this.queryDecode = decodeURI(this.query)
    this.seaching()
  },
  watch: {
    $route() {
      this.query = this.$route.query.q as string
      this.queryDecode = decodeURI(this.query)
      this.seaching()
    },
  },
  methods: {
    async seaching() {
      this.loading = true
      await this.init()
      this.loading = false
    },
    init() {
      return new Promise<void>(() => {
        this.getListClassroom()
        this.getListVideo()
        this.getListModule()
      })
    },
    async getListClassroom() {
      this.loadingClassroomData = true
      await ClassroomModel.searching(
        this.query,
        1,
        this.loadedClassroomData,
        'classroom'
      )
        .then(res => {
          this.loadingClassroomData = false
          if (res?.data?.classroom?.data?.length > 0) {
            this.listClassroomData = res.data.classroom.data
            this.totalClassroomData = res.data.classroom.pages.total
          }
        })
        .catch(err => {
          this.loadingClassroomData = false
          console.log(err)
        })
    },
    loadMoreListClassroom() {
      this.loadedClassroomData += 5
      if (this.loadedClassroomData > this.totalClassroomData) {
        this.loadedClassroomData = this.totalClassroomData
      }
      this.getListClassroom()
    },
    async getListVideo() {
      this.loadingVideoData = true
      await ClassroomModel.searching(
        this.query,
        1,
        this.loadedVideoData,
        'subject'
      )
        .then(res => {
          this.loadingVideoData = false
          if (res?.data?.subject?.data?.length > 0) {
            this.listVideoData = res.data.subject.data
            this.totalVideoData = res.data.subject.pages.total
          }
          // console.log('video',this.listVideoData)
        })
        .catch(err => {
          this.loadingVideoData = false
          console.log(err)
        })
    },
    loadMoreListVideo() {
      this.loadedVideoData += 5
      if (this.loadedVideoData > this.totalVideoData) {
        this.loadedVideoData = this.totalVideoData
      }
      this.getListVideo()
    },
    async getListModule() {
      this.loadingModuleData = true
      await ClassroomModel.searching(
        this.query,
        1,
        this.loadedModuleData,
        'module'
      )
        .then(res => {
          this.loadingModuleData = false
          if (res?.data?.module?.data?.length > 0) {
            this.listModuleData = res.data.module.data
            this.totalModuleData = res.data.module.pages.total
          }
        })
        .catch(err => {
          this.loadingModuleData = false
          console.log(err)
        })
    },
    loadMoreListModule() {
      this.loadedModuleData += 5
      if (this.loadedModuleData > this.totalModuleData) {
        this.loadedModuleData = this.totalModuleData
      }
      this.getListModule()
    },
  },
})
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
